import { defineComponent } from 'vue'

export const TgIcon = defineComponent({
	name: 'TgIcon',
	setup: () => {
		return () => (
			<svg
				width="13"
				height="12"
				viewBox="0 0 13 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M6.5 0C3.188 0 0.5 2.688 0.5 6C0.5 9.312 3.188 12 6.5 12C9.812 12 12.5 9.312 12.5 6C12.5 2.688 9.812 0 6.5 0ZM9.284 4.08C9.194 5.028 8.804 7.332 8.606 8.394C8.522 8.844 8.354 8.994 8.198 9.012C7.85 9.042 7.586 8.784 7.25 8.562C6.722 8.214 6.422 7.998 5.912 7.662C5.318 7.272 5.702 7.056 6.044 6.708C6.134 6.618 7.67 5.22 7.7 5.094C7.70417 5.07492 7.70361 5.0551 7.69838 5.03628C7.69316 5.01746 7.68341 5.0002 7.67 4.986C7.634 4.956 7.586 4.968 7.544 4.974C7.49 4.986 6.65 5.544 5.012 6.648C4.772 6.81 4.556 6.894 4.364 6.888C4.148 6.882 3.74 6.768 3.434 6.666C3.056 6.546 2.762 6.48 2.786 6.27C2.798 6.162 2.948 6.054 3.23 5.94C4.982 5.178 6.146 4.674 6.728 4.434C8.396 3.738 8.738 3.618 8.966 3.618C9.014 3.618 9.128 3.63 9.2 3.69C9.26 3.738 9.278 3.804 9.284 3.852C9.278 3.888 9.29 3.996 9.284 4.08Z"
					fill="currentColor"
				/>
			</svg>
		)
	}
})
